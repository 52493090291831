import { t } from 'ftc-common/i18n';
import Sponsor from './Sponsor'

export default function SponsorList({sponsors}) {
    if(sponsors.length === 0) return null;
    // The interval is 5 seconds for now because much faster felt distracting at least to me
    // and much slower means you aren't likely to ever see the local sponsors
    return <><h3>{t`Thank you to our sponsors!`}</h3>
<div id="sponsorCarousel" className="carousel slide" data-ride="carousel" data-interval="5000">
  <div className="carousel-inner">
    {window.sponsors.map((sponsor, idx) => <div key={sponsor.sponsorId} className={`carousel-item ${ idx === 0 ? 'active' : ''}`} style={{height: '230px;'}}>
      <Sponsor sponsor={sponsor}/>
    </div>)}
  </div>
</div>
</>;
}