import { t } from 'ftc-common/i18n';

function renderFirstRow(sponsor) {
    if (sponsor.season) {
        return <b><i>FIRST </i><sup>®</sup> Tech Challenge</b>;
    } else if(sponsor.region) {
        return <b>{t`${sponsor.region.name} Region`}</b>;
    } else if(sponsor.event) {
        return <b>{sponsor.event.name}</b>;
    }
}

export default function Sponsor({sponsor}) {
    let maxHeight = '80px';
    let maxWidth = '70%';
    if(sponsor.season && parseInt(sponsor.position) === 1) {
        maxHeight = '150px';
        maxWidth = '100%';
    } else if(sponsor.season) {
        maxHeight = '120px';
        maxWidth = '85%';
    }
    const NameHeadingType = sponsor.name && !sponsor.logo ? 'h1' : 'h2';
    let uploadUrl = null;
    if(sponsor.logo) {
        uploadUrl = typeof sponsor.logo == 'string' ? sponsor.logo : `/uploads/${sponsor.logo.uploadId}`;
    }

    return <><div style={{textAlign: 'center'}}>
        <h6 className="w-100">
          {renderFirstRow(sponsor)}
          { sponsor.title ? <><br/><b>{sponsor.title}</b></>
        : null }
        </h6>
      </div>

      <div className="d-flex align-items-center justify-content-center flex-column" style={{'height': '150px', 'margin': '20px 0'}}>
      { uploadUrl ? null :
        <NameHeadingType className="d-block text-center">{sponsor.name}</NameHeadingType> }
      { uploadUrl ?
        <img className="d-block" style={{maxHeight: maxHeight, maxWidth: maxWidth}} src={uploadUrl} alt={sponsor.name} />:
        null }
      </div>
    </>;
}